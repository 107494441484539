// import this after install `@mdi/font` package
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import colors from "vuetify/util/colors";

import { md2 } from "vuetify/blueprints";
import { md1 } from "vuetify/blueprints";
import { md3 } from "vuetify/blueprints";


export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: true,
  });
  app.vueApp.use(vuetify);
});
