<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
   <!-- <NavBar/> -->
  <div class="h-screen d-flex align-center justify-center pa-7">
    <div class="">
      <h2 class="text-h4 font-weight-bold">{{ error.statusCode }}</h2>
      <h2 class="text-h3 font-weight-bold">Error</h2>
      <p class="text-body-1">
        The page you are looking for does not exist. Check your url for errors
      </p>
      <v-btn size="large" variant="elevated" color="green" @click="handleError"
        >GO BACK TO HOME</v-btn
      >
    </div>
  </div>
</template>
