<script setup lang="ts">
useSeoMeta({
  title: "Lifeline: Home of Solutions",
  // titleTemplate: "%s - Site Title",
  ogTitle: "Lifeline: Home of Solutions",
  description:
    "A leading educational consultancy company in Ghana providing educational solutions to schools, parents and students.",
  ogDescription:
    "A leading educational consultancy company in Ghana providing Home Tuition, Consultancy and Guidance and Counseling",
  // ogImage: "https://example.com/image.png",
  twitterCard: "summary_large_image",
});
useHead({
  script: [
    {
      src: "	https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js",
      defer: true,
      async: true,
      tagPosition: "bodyClose",
    },

    {
      src: "https://www.google.com/recaptcha/api.js",
      async: true,
      defer: true,
      tagPosition: "bodyClose",
    },
  ],
});
</script>

<template>
  <NuxtLayout>
    <v-app>
      <NuxtPage />
    </v-app>
  </NuxtLayout>
</template>
